<template>
  <div>  
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <!-- <van-field
              v-model="username"
            >
              <template #input>
                <div class="titles">
                 <span class="titlesName">患者
                  <van-popover
                    v-model="showPopover"
                    theme="dark"
                    trigger="click"
                    :actions="actions"
                  >
                    <template #reference>
                      <span style="color:#a2a2a2"><van-icon name="info"/></span>
                    </template>
                  </van-popover>
                  </span>
                 <span>
                     <span class="information">
                       <span class="PleaseSelect">请选择</span>
                     </span>
                     <span><van-icon name="arrow" class="vanIcon"/></span>
                 </span>
                </div>
              </template>
            </van-field> -->

            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
            <van-field
              v-model="patientSex"
              :rules="[{ required: true, message: '请选择' }]"
            >
              <template #input>
                <div class="titles2">
                 <span class="titlesName">性别 <i>*</i></span>
                 <van-radio-group v-model="patientSex" direction="horizontal">
                   <van-radio name="1">男</van-radio>
                   <van-radio name="2">女</van-radio>
                 </van-radio-group>
                </div>
              </template>
            </van-field>
            <van-field
              v-model="tizhong"
              :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                 <span class="titlesName">体重 <i>*</i></span>
                 <span style="display: flex;align-items: center;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field v-model="tizhong" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                   <div class="unit"> <span>kg</span></div>
                 </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-model="shengao"
              :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                 <span class="titlesName">身高 <i>*</i></span>
                 <span style="display: flex;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field v-model="shengao" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                   <div class="unit"><span>cm</span></div>
                 </span>
                </div>
              </template>
            </van-field>

            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button"  block type="info" native-type="submit" style="width:90px">提交</van-button>
            </div>
          </van-form>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="Submitted">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>许文生氏(Stevenson)公式:：{{ this.xuwenshengshi }} m²</div>
            </div>
            <div v-if="this.patientSex == 1">
              <div class="CalculationDetail"><span></span>男性体表面积公式：{{ this.nanxing }} m²</div>
            </div>
            <div v-if="this.patientSex == 2">
              <div class="CalculationDetail"><span></span>女性体表面积公式：{{ this.nvxing }} m²</div>
            </div>
            <div>
              <div class="CalculationDetail"><span></span>中国通用公式：{{ this.zhongguotongyong }} m²</div>
            </div>
            <div>
              <div class="CalculationDetail"><span></span>杜波依斯氏(DuBois)公式：{{ this.duboyishishi }} m²</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <!-- <CalculationResults></CalculationResults> -->
      <div v-if="show">
        <div class="Standard2Description" v-if="Submitted">
          <div class="top2_nav"><span></span>结果</div>
          <div class="Standard2Deatil">
            <div class="result2Style">
              BSA(许文生氏)公式：{{ this.xuwenshengshi }} m²
            </div>
            <div class="result2Style" v-if="this.patientSex == 1">
              BSA(男性)：{{ this.nanxing }} m²
            </div>
            <div class="result2Style" v-if="this.patientSex == 2">
              BSA(女性)：{{ this.nvxing }} m²
            </div>
            <div class="result2Style">
              BSA(通用)：{{ this.zhongguotongyong }} m²
            </div>
            <div class="result2Style">
              BSA(DuBois)：{{ this.duboyishishi }} m²
            </div>
          </div>
        </div>

    </div>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="StandardDescription"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="references"></FormulaReferences>
    </div>
  </div>

</template>

<script>
import {Toast} from "vant";
import { getFormula , postFormula ,getFormulaHuiXian} from "@/service/BodyMassIndex_api";
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import router from "@/router";
import FormulaExplain from '@/components/template1/FormulaExplain'
import FormulaReferences from '../../../components/template1/FormulaReferences.vue';
// import CalculationResults from "@/components/CalculationResults";
// import { result } from "lodash";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
    //   searchValue:null,
    patientSex:"",
    formulaCode:'',
    nanxing:'',
    nvxing:'',
    Submitted:false,
    columnCode:'',
    formulaId:'',
    title:"",
    memberId: '1111',
    patientId:null,
    selectValue: '',
    shengao:"",
    tizhong:"",
    showPopover: false,
    StandardDescription:[],
    FormulaResult:"",
    references:[],
    zhongguo:"",
    guoji:"",
    xuwenshengshi:"",
    zhongguotongyong:'',
    duboyishishi:"",
    result:{},
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaReferences,
    FormulaExplain,
    // CalculationResults
  },

  watch:{
    "shengao":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "tizhong":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "patientSex":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    }

  },

  async created(){
   await this.getTheFormula()
   await this.showRecord()
  },
  methods:{
    // 回显数据
    async showRecord(){
      if(this.$router.currentRoute.query.location == 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId){ 
        const data = {
            id:this.$router.currentRoute.query.logId
          }
          const response = await getFormulaHuiXian(data)
        if (response.code === 0) {  
          // console.log("回显数据",response.data)
          this.tizhong = response.data.content.tizhong.value
          this.shengao = response.data.content.shengao.value
          if(response.data.content.xingbie.value == '女'){
            this.patientSex = '2'
          }else{
            this.patientSex = '1'
          }
          if (response.data.patientId) this.selectValue = {id: response.data.patientId, name: response.data.patientName};this.patientId = response.data.patientId
          // 计算结果
          this.Submitted = true
          this.xuwenshengshi = response.data.result.xuwenshengshi.value
          response.data.result.nvxing?this.nvxing = response.data.result.nvxing.value:""
          response.data.result.nanxing?this.nanxing = response.data.result.nanxing.value:""
          this.zhongguotongyong = response.data.result.zhongguotongyong.value
          this.duboyishishi = response.data.result.duboyishishi.value
          // console.log("id",this.memberId)
        } else {
          Toast.fail(response.message)
        }

        this.$nextTick(() => {
          this.show=true
          // this.compute = false
        })
      }
    },
    // 获取公式
    async getTheFormula(){
      // console.log("路由",router)
      var data = router.currentRoute.path.substring(9,router.currentRoute.path.Length)
      const response = await getFormula(this.$router.currentRoute.query.channel,data)
      if (response.code === 0) {  
        this.StandardDescription = response.data.explain // 存储标准说明
        this.references = response.data.references // 参考文献 
        this.title = response.data.chineseName //公式名称
        this.formulaCode = response.data.code
        this.formulaId = response.data.id
        this.columnCode = response.data.columnCode
        // console.log("公式数据",response.data)
      } else {
        Toast.fail(response.message)
      }
    },
    async onSubmit(){
      // 许文生氏
      this.xuwenshengshi = (0.0061 * this.shengao + 0.0128 * this.tizhong - 0.1529).toFixed(3) 
      // 男/女性体表面积
      if(this.patientSex == '1'){
        this.nanxing = (0.0057 * this.shengao + 0.0121 * this.tizhong + 0.0882).toFixed(3) 
      }else if(this.patientSex == '2'){
        this.nvxing = (0.0073 * this.shengao + 0.0127 * this.tizhong - 0.2106).toFixed(3) 
      }
      // 中国通用
      this.zhongguotongyong = (0.0061*this.shengao + 0.0124*this.tizhong - 0.0099).toFixed(3)
      // 杜波依斯氏(DuBois)公式
      this.duboyishishi= (0.007184 *  Math.pow(this.tizhong,0.425) *  Math.pow(this.shengao,0.725)).toFixed(3) 
      // console.log("第四个",this.duboyishishi)
      this.Submitted = true
      // let result = {}
      if (this.patientSex == '1'){ //男
        this.result = {
          zhongguotongyong:{value:this.zhongguotongyong,unit:"m²"},
          nanxing:{value:this.nanxing,unit:"m²"},
          xuwenshengshi:{value:this.xuwenshengshi,unit:"m²"},
          duboyishishi:{value:this.duboyishishi,unit:"m²"},
          DisplayResults :"BSA(许文生氏)公式："+ this.xuwenshengshi + "m²；" +"BSA(男性)：" + this.nanxing + "m²；" + "BSA(通用)：" + this.zhongguotongyong + "m²；" + "BSA(DuBois)：" + this.duboyishishi + "m²"
        }
      }else if(this.patientSex == '2'){ // 女
        this.result = {
          zhongguotongyong:{value:this.zhongguotongyong,unit:"m²"},
          // nanxing:{value:this.nanxing,unit:"m²"},
          nvxing:{value:this.nvxing,unit:"m²"},
          xuwenshengshi:{value:this.xuwenshengshi,unit:"m²"},
          duboyishishi:{value:this.duboyishishi,unit:"m²"},
          DisplayResults :"BSA(许文生氏)公式："+  this.xuwenshengshi + "m²；"+"BSA(女性)：" + this.nvxing + "m²；"+ "BSA(通用)：" + this.zhongguotongyong + "m²；"  + "BSA(DuBois)：" + this.duboyishishi + "m²"
        }
      }
      var data = {}
      if(this.$router.currentRoute.query.location == 'YXGS_RECORD_LIST'){
        data = {
          columnCode:this.columnCode,
          patientId:this.patientId,
          formulaId:this.formulaId,
          formulaCode:this.formulaCode,
          content:{ 
            tizhong:{value:this.tizhong , unit:"kg"},
            shengao:{value:this.shengao , unit:"cm"},
            xingbie:{value:this.patientSex == '1'? '男':'女'}
          },
          result:this.result,
          channel:this.$route.query.channel,
          location:"公式上新",
          id:this.$router.currentRoute.query.logId
        }
      }else{
        data = {
          columnCode:this.columnCode,
          patientId:this.patientId,
          formulaId:this.formulaId,
          formulaCode:this.formulaCode,
          content:{ 
            tizhong:{value:this.tizhong , unit:"kg"},
            shengao:{value:this.shengao , unit:"cm"},
            xingbie:{value:this.patientSex == '1'? '男':'女'}
          },
          result:this.result,
          channel:this.$route.query.channel,
          location:"公式上新",
        }
      }
      // console.log("查看数据",data)
      const response = await postFormula(data)
      if (response.code === 0) {  
        Toast.success('提交成功')
        this.show=true
      } else {
        Toast.fail(response.message)
      }
    },
    userChange (data) {
      this.selectValue = data
      // console.log("全部数据",this.selectValue)
      this.patientId = this.selectValue.id
      this.patientSex = this.selectValue.sex
    },
    // 开参考文献链接
    openLink(u){
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    }
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100vh;
  // overflow:scroll ;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px; 
        span {
          display: inline-block;
          width: 5px;
          height: 15px;
          background: #259beb;
          margin-right: 5px;
        }
    }
    .StandardDeatil{
      width:100%;
      padding: 15px 10px;
    //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references{
        margin-bottom:15px
      }
    }
    .StandardDeatil2{
      width:100%;
      padding: 15px 10px;
      padding-bottom:1px;
    //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references{
        margin-bottom:15px
      }
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
        padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 45px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
        width:60px
    }
    .unit{
      height: 45px;
    //   line-height: 45px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
  .titles2{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 45px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    padding-right:0;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
        width:60px
    }
    .unit{
      height: 45px;
    //   line-height: 45px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
      padding:0px 0px;
}
::v-deep .van-field__control{
    height: 45px;
    font-size: 16px;
}
::v-deep .van-field__error-message {
    position: absolute;
    top: 25px;
    left: 10px;
}
::v-deep p {
  padding: 0;
  margin: 0;
}

.Standard2Description{
    margin-top:10px;
    .top2_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px; 
      span {
        display: inline-block;
        width: 5px;
        height: 16px;
        background: #fe9712;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top2_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .Standard2Deatil{
    width:100%;
    padding: 15px 10px;
    padding-bottom:9px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    .result2Style{
      margin-bottom:6px;
      font-size: 20px;
      color: #2063aa;
      font-weight: 600;
    }
    .references{
      margin-bottom:15px
    }
  }
</style>
